import client from "./client";
import browserLogger from '../../../browser-logger';
import { graphqlError } from "../../../../shared/utility";
import { store } from '../../../../redux/store';

export const apiMainMutation = (
    mutation,
    variables
) => {
    return new Promise(resolve => {
        let objToBeReturned = { success: true, errorMessage: null, data: null };
        client
            .mutate({
                mutation,
                variables
            })
            .then(({ data }) => {
                objToBeReturned.data = data;
                resolve(objToBeReturned);
            })
            .catch(err => {
                const { customer } = store && store.getState() || {};
                browserLogger.error(
                  err.message,
                  {
                    error: err,
                    function: 'graphqlMutation',
                    cus_unique_id: customer && customer.cus_unique_id,
                  },
                );
                objToBeReturned = { ...objToBeReturned, success: false, errorMessage: graphqlError(err) };
                resolve(objToBeReturned);
            });
    });
}