export default {
    // openGraph: {
    //   type: 'website',
    //   locale: 'en_IE',
    //   url: 'https://www.url.ie/',
    //   site_name: 'SiteName',
    // },
    // twitter: {
    //   handle: '@handle',
    //   site: '@site',
    //   cardType: 'summary_large_image',
    // },
    title: "Remitbee Online Money Transfer | Send money online",
    description: "Stop overpaying banks sending your money online. With Remitbee you can transfer your money abroad with the best exchange rates and lowest fees."
        
};