import { brazeSessionStart } from "../../redux/customer/actions";
import { store } from "../../redux/store";
import braze_events from "./braze-events";
import braze_property from "./braze-properties";
import firebase from "firebase/app";
import "firebase/messaging";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

// for Staging Testing, will remove before release
const isProd = process.env.REACT_APP_ENV === 'prod' || process.env.REACT_APP_ENV === 'staging';
const isStaging = process.env.REACT_APP_ENV === 'staging';

const firebaseConfig = {
    apiKey: process.env.FIREBASE_API_KEY,
    authDomain: `${process.env.FIREBASE_PROJECT_ID}.firebaseapp.com`,
    databaseURL: `https://${process.env.FIREBASE_DATABASE_NAME}.firebaseio.com`,
    projectId: process.env.FIREBASE_PROJECT_ID,
    storageBucket: `${process.env.FIREBASE_PROJECT_ID}.appspot.com`,
    messagingSenderId: process.env.FIREBASE_SENDER_ID,
    appId: process.env.FIREBASE_APP_ID,
};

const startPushNotifications = () => {
    if (typeof window === 'undefined' || typeof navigator === 'undefined' || !isProd) return;
    import("./braze-exports").then(({ requestPushPermission }) => {
        // Firebase Cloud Messaging (FCM) Client SDK config
        initializeApp(firebaseConfig);
        const messaging = getMessaging();

        requestPushPermission(
            (endpoint, publicKey, userAuth) => {
                // Success callback - push registration successful
                console.log('Push registration successful with Braze.');
                console.log('Endpoint:', endpoint);
                console.log('Public Key:', publicKey);
                console.log('User Auth:', userAuth);
                
                // Now you can get the FCM token and process it
                getToken(messaging, { vapidKey: process.env.FIREBASE_VAPID_KEY })
                    .then((currentToken) => {
                        if (currentToken) {
                            // Pass FCM token to backend and Braze
                            console.log("FCM Token retrieved:", currentToken);
                            // Add logic here to sync token with your backend or Braze
                        } else {
                            console.log("No registration token available.");
                        }
                    })
                    .catch((err) => {
                        console.error("An error occurred while retrieving FCM token.", err);
                    });
            },
            (temporaryDenial) => {
                // Denied callback - push registration failed or denied
                if (temporaryDenial) {
                    console.error('Temporary push permission denial.');
                } else {
                    console.error('Permanent push permission denial.');
                }
            }
        );
    }).catch((err) => {
        console.error("Failed to initialize Braze:", err);
    });
};

export const initializeBraze = (userIdentifier = null) => {
    if (typeof window === 'undefined' || typeof navigator === 'undefined' || !isProd) return;
    import("./braze-exports").then(({ initialize, openSession, changeUser, setLogger }) => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.register('/service-worker.js')
              .then((registration) => {
                console.log('ServiceWorker registration successful with scope:', registration.scope);
              })
              .catch((error) => {
                console.error('ServiceWorker registration failed:', error);
              });
        }
        initialize(process.env.BRAZE_API_KEY_CP, {
            baseUrl: `sdk.iad-07.braze.com`,
            enableLogging: isStaging,
        });
        const { cus_unique_id } = store?.getState()?.customer;
        (userIdentifier || cus_unique_id) && changeUser(userIdentifier || cus_unique_id)
        isStaging && setLogger(function(message) {
            console.log("Braze Custom Logger: " + message);
        });
        openSession();

        isStaging && Notification.requestPermission().then((permission) => {
            if (permission === "granted") startPushNotifications();
        }).catch((err) => {
            console.error("Failed to request push notification permission:", err);
        });
    }).catch((err) => {
        console.error("Failed to initialize Braze:", err);
    });
};

const checkBrazeAvailable = async (userIdentifier = null) => {
    // Check redux store if 'is_braze_enabled'
    const { is_braze_enabled, cus_unique_id } = store.getState().customer;
    
    // Check if Braze session is already initialized
    const isBrazeInitialized = sessionStorage.getItem('braze_initialized');

    if (!isBrazeInitialized || !is_braze_enabled){
        // Check API if braze is enabled from DB
        const { success, data } = await brazeSessionStart();
        if (!(success && data && data?.isBrazeEnabled)) return;
        initializeBraze(userIdentifier || cus_unique_id);
        sessionStorage.setItem('braze_initialized', 'true');
        return;
    }
}



export const updateBrazeUser = (userIdentifier) => {
    // check if session is active, if not then start session
    checkBrazeAvailable(userIdentifier);

    if (typeof window === 'undefined' || typeof navigator === 'undefined' || !isProd) return;
    import("./braze-exports.js").then(({ changeUser }) => {
        changeUser(userIdentifier);
        isStaging && Notification.requestPermission().then((permission) => {
            if (permission === "granted") startPushNotifications();
        }).catch((err) => {
            console.error("Failed to request push notification permission:", err);
        });
    }).catch(error => {
        console.log('Failed to update Braze user:', error);
    });
};


export const logBrazeEvent = ({eventName, eventProperties}) => { 
    // check if session is active, if not then start session   
    checkBrazeAvailable();

    if (typeof window === 'undefined' || typeof navigator === 'undefined' || !isProd) return;

    import("./braze-exports.js").then(({ logCustomEvent }) => {
        if(eventName in braze_events){

            // filter out non-exiting properties in Braze
            const validProperty = Object.keys(eventProperties)?.reduce((acc, key) => {
                
                // Check if the key exists in BrazeProperties
                if (braze_property?.includes(key)) {
                  const value = eventProperties[key];
              
                  // Do not send null or undefined values
                  if (value !== null && value !== undefined) {
                    acc[key] = value;
                  }
                }
                return acc;
              }, {});

            logCustomEvent(eventName, validProperty);
        }
    }).catch(error => {
        console.log('Failed to update Braze user:', error);
    });
}
