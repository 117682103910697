/* eslint-disable no-undef */
import Script from 'next/script';
import React from 'react';
import { ACTIONS } from './actions';

const isProd = process.env.REACT_APP_ENV === 'prod';
import * as fbq from "./fpixel";
import { useEffect } from 'react';
import { useRouter } from 'next/router';

export const facebookScript = () => {
    const router = useRouter();
    useEffect(() => fbq.pageview(), [router?.asPath]);
    return (<>
        {/* Global Site Code Pixel - Facebook Pixel */}
        <Script
            id="fb-pixel"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
            __html: `
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://${process.env.REACT_APP_ENV === 'prod' ? 'connect.facebook.net' : process.env.FACEBOOK_PROXY}/en_US/fbevents.js');
                fbq('init', ${fbq.FB_PIXEL_ID});
            `,
            }}
        />
    </>)
}

const FB_PIXEL_EVENTS = [
    ACTIONS.SIGNUP_INITIATED,
    ACTIONS.PROMO_CODE_APPLIED,

    // Send Money flow
    ACTIONS.SEND_MONEY_INITIATED,
    ACTIONS.SEND_MONEY_COUNTRY_SELECTED,
    ACTIONS.SEND_MONEY_AMOUNT_CONFIRMED,
    ACTIONS.SEND_MONEY_PURPOSE_CONFIRMED,
    ACTIONS.SEND_MONEY_RECEIVING_METHOD_CONFIRMED,
    ACTIONS.SEND_MONEY_PERSONA_INITIATED,
    ACTIONS.SEND_MONEY_PERSONA_COMPLETED,
    ACTIONS.SEND_MONEY_PERSONA_CANCELLED,
    ACTIONS.SEND_MONEY_PERSONA_ERROR,
    ACTIONS.SEND_MONEY_RECIPIENT_DETAILS_CONFIRMED,
    ACTIONS.SEND_MONEY_CUSTOMER_DETAILS_CONFIRMED,
    ACTIONS.SEND_MONEY_CUSTOMER_ADDRESS_CONFIRMED,
    ACTIONS.SEND_MONEY_CUSTOMER_OCCUPATION_CONFIRMED,
    ACTIONS.SEND_MONEY_PAYMENT_METHOD_SELECTED,
    ACTIONS.SEND_MONEY_PAYMENT_DETAILS_CONFIRMED,
    ACTIONS.SEND_MONEY_TRANSFER_OVERVIEW_CONFIRMED,
    ACTIONS.SEND_MONEY_TRANSACTION_COMPLETED,

    // Currency Exchange flow
    ACTIONS.CURRENCY_EXCHANGE_INITIATED,
    ACTIONS.CURRENCY_EXCHANGE_AMOUNT_CONFIRMED,
    ACTIONS.CURRENCY_EXCHANGE_PAYMENT_BANK_ACCOUNT_SELECTED,
    ACTIONS.CURRENCY_EXCHANGE_PERSONA_INITIATED,
    ACTIONS.CURRENCY_EXCHANGE_PERSONA_COMPLETED,
    ACTIONS.CURRENCY_EXCHANGE_PERSONA_CANCELLED,
    ACTIONS.CURRENCY_EXCHANGE_PERSONA_ERROR,
    ACTIONS.CURRENCY_EXCHANGE_CUSTOMER_DETAILS_CONFIRMED,
    ACTIONS.CURRENCY_EXCHANGE_CUSTOMER_ADDRESS_CONFIRMED,
    ACTIONS.CURRENCY_EXCHANGE_CUSTOMER_OCCUPATION_CONFIRMED,
    ACTIONS.CURRENCY_EXCHANGE_SELECT_PREFERRED_BANK,
    ACTIONS.CURRENCY_EXCHANGE_DEPOSIT_BANK_ACCOUNT_SELECTED,
    ACTIONS.CURRENCY_EXCHANGE_PAYMENT_BANK_ACCOUNT_SELECTED,
    ACTIONS.CURRENCY_EXCHANGE_MANUAL_PAYMENT_ACCOUNT_CONNECTION,
    ACTIONS.CURRENCY_EXCHANGE_MANUAL_DEPOSIT_ACCOUNT_CONNECTION,
    ACTIONS.CURRENCY_EXCHANGE_PURPOSE_CONFIRMED,
    ACTIONS.CURRENCY_EXCHANGE_TRANSFER_OVERVIEW_CONFIRMED,
    ACTIONS.CURRENCY_EXCHANGE_OVERVIEW_CONFIRMED,

    ACTIONS.MOBILE_TOP_UP_INITIATED,
    ACTIONS.MOBILE_TOP_UP_COUNTRY_SELECTED,
    ACTIONS.MOBILE_TOP_UP_PHONE_NUMBER_SUBMITTED,
    ACTIONS.MOBILE_TOP_UP_CARRIER_CHANGED,
    ACTIONS.MOBILE_TOP_UP_CARRIER_CHANGED,
    ACTIONS.MOBILE_TOP_UP_VALUE_SELECTED,
    ACTIONS.MOBILE_TOP_UP_EXTRA_INFORMATION_SUBMITTED,
    ACTIONS.MOBILE_TOP_UP_PAYMENT_SELECTED,
    ACTIONS.MOBILE_TOP_UP_PAYMENT_DETAILS_ENTERED,
    ACTIONS.MOBILE_TOP_UP_OVERVIEW_CONFIRMED,
    ACTIONS.MOBILE_TOP_UP_TRANSACTION_COMPLETED,

    ACTIONS.GIFT_CARD_INITIATED,
    ACTIONS.GIFT_CARD_COUNTRY_SELECTED,
    ACTIONS.GIFT_CARD_PROVIDER_SELECTED,
    ACTIONS.GIFT_CARD_VALUE_SELECTED,
    ACTIONS.GIFT_CARD_PAYMENT_SELECTED,
    ACTIONS.GIFT_CARD_PAYMENT_DETAILS_ENTERED,
    ACTIONS.GIFT_CARD_OVERVIEW_CONFIRMED,
    ACTIONS.GIFT_CARD_TRANSACTION_COMPLETED,

    ACTIONS.BILL_PAYMENT_INITIATED,
    ACTIONS.BILL_PAYMENT_COUNTRY_SELECTED,
    ACTIONS.BILL_PAYMENT_BILL_TYPE_SELECTED,
    ACTIONS.BILL_PAYMENT_PROVIDER_AND_ACCOUNT_NUMBER_SELECTED,
    ACTIONS.BILL_PAYMENT_VALUE_SELECTED,
    ACTIONS.BILL_PAYMENT_EXTRA_INFORMATION_SUBMITTED,
    ACTIONS.BILL_PAYMENT_PAYMENT_SELECTED,
    ACTIONS.BILL_PAYMENT_PAYMENT_DETAILS_ENTERED,
    ACTIONS.BILL_PAYMENT_OVERVIEW_CONFIRMED,
    ACTIONS.BILL_PAYMENT_TRANSACTION_COMPLETED,
];

export const trackFBEvent = (action, payload, meta = {}) => {
    if (isProd && window) {
        const eventID = crypto.randomUUID();
        switch (action) {
            case ACTIONS.SIGNUP_COMPLETED:
                const fbTrack = { ...payload?.personalInformation, email: payload?.personalInformation?.cus_email };
                // const fbTrack = { email: payload.personalInformation.cus_email };
                fbq.event(ACTIONS.SIGNUP_COMPLETED, fbTrack, {eventID: eventID});
                break;
            case ACTIONS.LOGIN:
                if (payload) {
                    const fbTrack = { email: payload.cus_email };
                    fbq.event('ACTIONS.LOGIN', fbTrack, {eventID: eventID});
                }
                break;
        }
        if (FB_PIXEL_EVENTS.includes(action)) {
            const _action = meta.accountType === 'business' ? 'BA_' + action : action;
            fbq.event(_action, payload, {eventID: eventID});
        }
    }

}