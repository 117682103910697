import { store } from '../../redux/store';
import braze_events from './braze-events';
import braze_property from './braze-properties';
import { brazeSessionStart } from '../../redux/customer/actions';
import actionTypes from '../../redux/customer/actionTypes';
const isStagingProd = process.env.REACT_APP_ENV === 'prod' || process.env.REACT_APP_ENV === 'staging';
const isStaging = process.env.REACT_APP_ENV === 'staging';

export const startPushNotifications = (braze) => {
  if (typeof window === 'undefined' || typeof navigator === 'undefined' || !braze || !isStagingProd) return;

    if (!braze.isPushPermissionGranted()) {
    Notification.requestPermission().then((permission) => {
        if (permission === 'granted') braze.requestPushPermission();
    });
    } else if (!braze.isPushBlocked()) braze.requestPushPermission();
};

export const loadBrazeContentCards = async (braze) => {
    if (typeof window === 'undefined' || typeof navigator === 'undefined' || !braze || !isStagingProd) return;

    braze.requestContentCardsRefresh();
    const contentCards = braze.getCachedContentCards();
    if (contentCards?.cards && contentCards?.cards?.length > 0) {
        store.dispatch({
            type: actionTypes.SET_BRAZE_CONTENT_CARDS,
            payload: contentCards?.cards,
        });
    }
    braze.subscribeToContentCardsUpdates(function (content) {
        store.dispatch({
            type: actionTypes.SET_BRAZE_CONTENT_CARDS,
            payload: content?.cards,
        });
    }, contentCards);
      
};

export const initializeBraze = (sdk) => {
    if (typeof window === 'undefined' || typeof navigator === 'undefined' || !isStagingProd) return;

    if ('serviceWorker' in navigator) {
        window.addEventListener('load', function () {
            navigator.serviceWorker.register('/service-worker.js').then(
                (registration) => { if (sdk) sdk.openSession(); },
                (error) => console.log('Service Worker registration failed: ', error)
            );
        });
    }

    if (sdk) {
        sdk.initialize(process.env.BRAZE_API_KEY_CP, {
            baseUrl: `sdk.iad-07.braze.com`,
            enableLogging: isStaging,
            allowUserSuppliedJavascript: true,
        });
        isStaging && sdk.setLogger(function (message) {
            console.log(message);
        });
    }
};

const checkBrazeAvailable = async () => {
    import('@braze/web-sdk').then(async (sdk) => {
        if(!sdk) return;

        // Check Braze Session Start in browser storage
        const braze_initialized = sessionStorage.getItem('braze_initialized');

        // Check Braze Session Start in Redux Store
        const { is_braze_enabled } = store?.getState()?.customer;

        if (!(braze_initialized && is_braze_enabled)) {
            // API call to check if Braze is enabled from Core
            const { success, data } = await brazeSessionStart();
            if (!(success && data && data?.isBrazeEnabled)) return;
            initializeBraze(sdk);
            sessionStorage.setItem('braze_initialized', 'true');
        }
    });
}

export const updateBrazeUser = (userIdentifier) => {

    if (typeof window === 'undefined' || typeof navigator === 'undefined' || !isStagingProd) return;

    // If both the conditions are not true, initialize Braze
    import('@braze/web-sdk').then(async (sdk) => {
        // Check Braze session start 
        await checkBrazeAvailable();

        // Content Cards
        loadBrazeContentCards(sdk);
        
        // In App Messages
        sdk.subscribeToInAppMessage(function (inAppMessage) {
            sdk.showInAppMessage(inAppMessage);
        });
        
        // Show In App Messages
        sdk.automaticallyShowInAppMessages();
        
        // Push Notifications
        startPushNotifications(sdk);

        // Change Braze Session User
        sdk.changeUser(userIdentifier);
    });
};

export const contentCardClick = (card) => {
    if (typeof window === 'undefined' || typeof navigator === 'undefined') return;
    import("./braze-exports.js").then(({ logContentCardClick }) => {
        logContentCardClick(card);
    });
}


export const logBrazeEvent = async ({eventName, eventProperties}) => { 
    // Check Braze session start   
    await checkBrazeAvailable();

    if (typeof window === 'undefined' || typeof navigator === 'undefined' || !isStagingProd) return;

    import("./braze-exports.js").then(({ logCustomEvent }) => {
        if(eventName in braze_events){

            // filter out non-exiting properties in Braze
            const validProperty = Object.keys(eventProperties)?.reduce((acc, key) => {
                
                // Check if the key exists in BrazeProperties
                if (braze_property?.includes(key)) {
                  const value = eventProperties[key];
              
                  // Do not send null or undefined values
                  if (value !== null && value !== undefined) {
                    acc[key] = value;
                  }
                }
                return acc;
              }, {});

            logCustomEvent(eventName, validProperty);
        }
    }).catch(error => {
        console.log('Failed to update Braze user:', error);
    });
}
