import browserLogger from '../../../browser-logger';
import client from "./client";
import { store } from '../../../../redux/store';

export const apiMainQuery = (
    query,
    variables,
    fetchPolicy = 'network-only'
) => {
    return new Promise(resolve => {
        let objToBeReturned = { success: true, errorMessage: null, data: null };
        client
            .query({
                variables,
                query,
                fetchPolicy
            })
            .then(({ data }) => {
                objToBeReturned.data = data;
                resolve(objToBeReturned);
            })
            .catch(err => {
                const { customer } = store && store.getState() || {};
                browserLogger.error(
                    err.message,
                    {
                        error: err,
                        function: 'graphqlQuery',
                        cus_unique_id: customer && customer.cus_unique_id
                    },
                );
                //log to Sentry
                console.log({
                    error: err,
                    cus_unique_id: customer && customer.cus_unique_id,
                    query: query && query.definitions && query.definitions.length && query.definitions[0]['name'] && query.definitions[0]['name'].value
                })
                objToBeReturned = { ...objToBeReturned, success: false, errorMessage: err.message };
                resolve(objToBeReturned);
            });
    });
};